import { http } from './config';

export default{
    show:() => {
        return http.get('user/show')
    },
    update:(user) => {
        return http.post('user/update', user)
    },
    store:(user) => {
        return http.post('user', user)
    },
    forgotPassword:(user) => {
        return http.post('user/password/forgot', user)
    },
    resetPassword:(data) =>{
        return http.post('user/password/reset', data)
    },
    conformMail:(idUser, token) =>{
        return http.get('user/' + idUser + '/verifyemail/' + token)
    },
    exists:(user) =>{
        return http.post('user/exists',user)
    }
}