import Vue from 'vue'
import App from './App.vue'
import router from '@/routes'
import store from '@/store'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import VueConfirmDialog from 'vue-confirm-dialog'
import VueTheMask from 'vue-the-mask'

Vue.use(VueConfirmDialog)
Vue.use(VueTheMask)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
