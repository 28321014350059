<template>
  <div id="app">
    <vue-confirm-dialog></vue-confirm-dialog>

    <!-- MODAL - MENU MOB ---------------------------------------------------- -->
    <div class="modal" id="modalMenuMob" tabindex="-1" aria-labelledby="modalMenuMobLabel" aria-hidden="true" ref="modalMob">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content border-0 bg-light">

          <div class="modal-header border-0">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          
          <div class="modal-body p-3 pt-0 pb-4">
            <div class="container rs-menu-mob">
              <div class="row">

                <!-- MENU -->
                <div class="col-12 text-center">
                  <div v-if="showUserLoggedMenu">  
                    <h2 class="mb-4 rs-logo m-auto"><a href="../../assets/pages/index.html?dash"><img src="assets/img/ilustras/rs-marca-fundo-claro.svg" width="" alt="Relógio Seguro | By eWatch"></a></h2>
                  </div>
                  <div v-if="!showUserLoggedMenu">
                    <h2 class="mb-4 rs-logo m-auto"><a href="../../assets/pages/index.html"><img src="assets/img/ilustras/rs-marca-fundo-claro.svg" width="" alt="Relógio Seguro | By eWatch"></a></h2>
                  </div>
                  <nav>
                    <ul v-if="showUserLoggedMenu" class="nav flex-column text-center list-unstyled mb-3 rs-menu-dashboard">
                        <li><router-link data-bs-dismiss="modal" v-on:click.native="navigate('home')" to="home" class="nav-link">Dashboard</router-link></li>
                        <li><router-link data-bs-dismiss="modal" v-on:click.native="navigate('watchreport')" to="watchreport" class="nav-link">Ocorrências</router-link></li>
                        <li><router-link data-bs-dismiss="modal" v-on:click.native="navigate('watchtransfer')" to="watchtransfer" class="nav-link">Transferências</router-link></li>
                        <li><router-link data-bs-dismiss="modal" v-on:click.native="navigate('storewatch')" to="storewatch" class="nav-link">Novo Relógio</router-link></li>
                        <li><router-link data-bs-dismiss="modal" v-on:click.native="navigate('account')"  to="account" class="nav-link">Minha conta</router-link></li>
                        <li><a class="nav-link" href="#" v-on:click.prevent="logout()" title="">Sair</a></li>
                    </ul>
                    
                    <ul class="nav d-flex flex-column text-center list-unstyled">
                      <div v-if="showUserLoggedMenu">
                        <li><a class="nav-link" href="assets/pages/index.html?dash" title="">Início</a></li>
                      </div>
                      <div v-if="!showUserLoggedMenu">
                        <li><a class="nav-link" href="assets/pages/index.html" title="">Início</a></li>
                      </div>

                      <div v-if="showUserLoggedMenu">
                        <li><a class="nav-link" href="assets/pages/advantage.html?dash" title="">Vantagens</a></li>
                      </div>
                       <div v-if="!showUserLoggedMenu">
                        <li><a class="nav-link" href="assets/pages/advantage.html" title="">Vantagens</a></li>
                      </div>
                      
                      <div v-if="showUserLoggedMenu">
                        <li><a class="nav-link" href="assets/pages/help.html?dash" title="">Ajuda</a></li>
                      </div>
                      <div v-if="!showUserLoggedMenu">
                        <li><a class="nav-link" href="assets/pages/help.html" title="">Ajuda</a></li>
                      </div>
                      <li><router-link data-bs-dismiss="modal" v-on:click.native="navigate('partners')"  to="partners" class="nav-link">Parceiros</router-link></li>
                    </ul>
                    <div v-if="!showUserLoggedMenu">
                      <ul class="nav d-flex flex-column text-center list-unstyled mb-4">
                        <li><router-link data-bs-dismiss="modal" v-on:click.native="navigate('login')"  to="login" class="nav-link">Login</router-link></li>
                        <li><router-link data-bs-dismiss="modal" v-on:click.native="navigate('register')" to="register" class="btn btn-outline-primary btn-sm text-dark  mt-3">Criar conta</router-link></li>
                      </ul>
                    </div>
                  </nav>
                </div>
                  
                <!-- CONTATO -->
                <div class="col-12 text-center">
                  <h6 class="nav-link pb-0 mb-0">Contato</h6>
                  <p><small>adm@ewatchboutique.com</small></p>
                </div>

                <!-- REDES -->
                <div class="col-12 text-center pt-2">                  
                    <a href="https://www.instagram.com" class="text-dark">
                    <i class="bi bi-instagram rs-icone align-middle"></i>
                    <small>@relogioseguro</small></a>
                </div>

              </div>
            </div>
          </div>

        </div>  
      </div>
    </div>
    <!-- INICIO HEADER HERO -------------------------------------  -->
    <header>
      <div class="container-fluid pt-5">
        
        <!-- NAVBAR -->
        <div class="container-fluid rs-navbar rs-navbar-claro fixed-top rs-seguranca-mob">
          <div class="row rs-maxwidth py-2 py-md-3">
            <!-- LOGO -->
            <div class="col-7 col-sm-4 col-md-3 p-0 rs-logo">
              <div v-if="showUserLoggedMenu">
                <h1 class="mb-0"><a class="d-flex" href="assets/pages/index.html?dash"><img src="assets/img/ilustras/rs-marca-fundo-claro.svg" alt="Relógio Seguro | By eWatch"></a></h1>
              </div>
              <div v-if="!showUserLoggedMenu">
                <h1 class="mb-0"><a class="d-flex" href="assets/pages/index.html"><img src="assets/img/ilustras/rs-marca-fundo-claro.svg" alt="Relógio Seguro | By eWatch"></a></h1>
              </div>
            </div>  
        
        <!-- NAV DESK -->
        <div class="col p-0 d-flex flex-row justify-content-end align-items-center">
            <nav class="d-none d-lg-block">
                <ul class="d-flex align-items-center list-unstyled m-0 fw-light">
                    <div v-if="showUserLoggedMenu">
                      <li><a href="assets/pages/index.html?dash" title="">Início</a></li>
                    </div>
                    <div v-if="!showUserLoggedMenu">
                      <li><a href="assets/pages/index.html" title="">Início</a></li>
                    </div>

                      <div v-if="showUserLoggedMenu">
                        <li><a href="assets/pages/advantage.html?dash" title="">Vantagens</a></li>
                      </div>
                       <div v-if="!showUserLoggedMenu">
                        <li><a href="assets/pages/advantage.html" title="">Vantagens</a></li>
                      </div>
                      
                      <div v-if="showUserLoggedMenu">
                        <li><a href="assets/pages/help.html?dash" title="">Ajuda</a></li>
                      </div>
                      <div v-if="!showUserLoggedMenu">
                        <li><a href="assets/pages/help.html" title="">Ajuda</a></li>
                      </div>

                    
                    <li><router-link to="partners" class="nav-link">Parceiros</router-link></li>
                    <!--
                        // Exibe quando não logado.
                        // Remover classe "d-none"
                    -->
                      <li><router-link v-if="!showUserLoggedMenu" to="login">Login</router-link></li>
                      <li><router-link v-if="!showUserLoggedMenu" to="register" class="btn btn-outline-primary btn-sm text-dark">Criar conta</router-link></li>
                </ul>               
            </nav>
            <div class="d-flex align-items-center">
                <a class="d-lg-none me-2" data-bs-toggle="modal" data-bs-target="#modalMenuMob"><i class="bi bi-list rs-icone text-dark text-muted" style="font-size: 2rem;"></i></a>
                <router-link v-if="showUserLoggedMenu" to="account" class="">
                  <img class="rounded-circle" height="auto" width="40" :src="$store.state.userLogged.avatar ? $store.state.userLogged.avatar : require('@/assets/images/avatar_default.png') " alt="">
                </router-link>
            </div>          
        </div>  
    </div>
</div>
      </div>
    </header>
    <!-- FIM HEADER HERO -->

<section class="container rs-maxwidth  pt-sm-3 pt-lg-4">
  <div class="row justify-content-center rs-seguranca-mob gap-3">
      <!-- MENU LATERAL -->
          <nav v-if="showUserLoggedMenu" class="col-md-2 d-none d-lg-block p-0 rs-menu-dashboard">
            <ul class="nav flex-column mb-3">
              <li><router-link to="home" class="nav-link">Dashboard</router-link></li>
              <li><a class="nav-link" v-on:click="search()"  style="cursor: pointer;">Consultar</a></li>
              <li><router-link to="watchreport" class="nav-link">Ocorrências</router-link></li>
              <li><router-link to="watchtransfer" class="nav-link">Transferências</router-link></li>
              <li><router-link to="storewatch" class="nav-link">Novo Relógio</router-link></li>
            </ul> 
            <ul class="nav border-top mb-3 pt-3 rs-menu-apoio">
              <li><router-link to="account" class="nav-link">Minha conta</router-link></li>
              <li><a class="nav-link" href="#" v-on:click.prevent="logout()" title="">Sair</a></li>              
            </ul>
          </nav>   
      <!-- MENU LATERAL -->        
      <div class="col p-0">
          <router-view />
      </div>
        <div v-if="showUserLoggedMenu" id="banner-sidebar" class="col-md-3 d-none d-lg-block">            
          <div class="card">
            <div class="card-body rounded">
              <h5 class="card-title fw-light">Banner estratégico com um botão CTA</h5>
              <p class="card-text"><small>Em telas com resolução menor que 992px (lg) este sidebar é ocultado.</small></p>
              <a href="site-padrao.php" class="btn btn-secondary btn-sm">btn CTA</a>
            </div>
          </div>         
        </div>
      </div>

      </section>


    <footer class="container-fluid bg-light py-4 py-sm-5 text-dark">
      <div class="container rs-maxwidth">
        <div class="row rs-seguranca-mob">

          <!-- INSTITUCIONAL -->
          <div class="col-md-5 col-lg-6 text-center text-md-start mb-0">
            <div v-if="showUserLoggedMenu">
              <h2 class="mb-4 rs-logo mx-sm-auto ms-lg-0"><a href="assets/pages/index.html?dash"><img src="assets/img/ilustras/rs-marca-fundo-claro.svg" width="" alt="Relógio Seguro | By eWatch"></a></h2>
            </div>
            <div v-if="!showUserLoggedMenu">
              <h2 class="mb-4 rs-logo mx-sm-auto ms-lg-0"><a href="assets/pages/index.html"><img src="assets/img/ilustras/rs-marca-fundo-claro.svg" width="" alt="Relógio Seguro | By eWatch"></a></h2>
            </div>
            <!-- <p class="mb-3"><small>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</small> </p> -->
            <!-- <p class="text-muted rs-confirma-termos"><small><a href="site-padrao.php">Política de Privacidade</a> e <a href="site-padrao.php">Termos de uso</a>.</small></p> -->
            <!-- <p class="text-muted"><small>CNPJ 000.000.000/0001-00</small></p> -->
          </div>

          <!-- NAV/CONTATO -->
          <div class="col-md d-none d-md-block">
            <div class="container-fluid">

              <div class="row mb-4">
                <!-- NAV -->
                <div class="col-md-6 text-center text-md-start">
                  <nav>
                    <ul class="d-flex flex-column list-unstyled gap-2">
                      <div v-if="showUserLoggedMenu">
                        <li><a href="assets/pages/index.html?dash" title="">Início</a></li>
                      </div>
                      <div v-if="!showUserLoggedMenu">
                        <li><a href="assets/pages/index.html" title="">Início</a></li>
                      </div>
                      
                      <div v-if="showUserLoggedMenu">  
                        <li><a href="assets/pages/advantage.html?dash" title="">Vantagens</a></li>
                      </div>
                      <div v-if="!showUserLoggedMenu">  
                        <li><a href="assets/pages/advantage.html" title="">Vantagens</a></li>
                      </div>

                      <div v-if="showUserLoggedMenu">
                        <li><a href="assets/pages/help.html?dash" title="">Ajuda</a></li>
                      </div>
                      <div v-if="!showUserLoggedMenu">
                        <li><a href="assets/pages/help.html" title="">Ajuda</a></li>
                      </div>
                      
                      <li><router-link to="partners" class="">Parceiros</router-link></li>
                    </ul>                    
                  </nav>
                </div>
                <!-- conta -->
                <div class="col-md-6 text-center text-md-start">
                    <nav>
                      <ul class="d-flex flex-column list-unstyled gap-2">
                          <li><router-link to="login">Login</router-link></li>
                          <li><router-link to="register">Criar conta</router-link></li>
                      </ul>
                    </nav>              
                </div>
              </div>
              <div class="row">
                <!-- CONTATO -->
                <div class="col-md-6 text-center text-md-start">
                  <h6>Contato</h6>
                  <p><small>adm@ewatchboutique.com</small></p>
                </div>
                <!-- REDES -->
                <div class="col-md-6 text-center text-md-start pt-3">                  
                    <a href="https://www.instagram.com" class="text-dark">
                      <i class="bi bi-instagram rs-icone align-middle"></i>
                      <small>  @relogioseguro</small>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <WatchStatusSearch ref="watchStatusSearch"/> 

  </div>
</template>

<script>

import AuthRelogioSeguroAPI from '@/services/relogioseguroapi/auth'
import WatchStatusSearch from '@/components/WatchStatusSearch'

export default {
    data(){
      return {
        isUserLogged: false,
      }
    },
    components:{
        WatchStatusSearch
    },
    computed: {
      showUserLoggedMenu(){
        return this.isUserLogged
      }
    },
    methods:{
      navigate(to){
        if(this.$route.name != to){
          this.$router.push(to)
        }
      },
      search(){
        this.$refs.watchStatusSearch.visible = true
      },
      logout() {
        AuthRelogioSeguroAPI.logout()
        this.isUserLogged = false
        this.$store.state.userLogged = null;
        this.$router.push('/')
      },
      updateNavBar(){
        //this.$nextTick(function () {
          if (!this.$store.state.userLogged){
            this.$store.state.userLogged = JSON.parse(localStorage.getItem('userLogged'))
            if (this.$store.state.userLogged){
              this.isUserLogged = true;
            }
          }
        //})
      },
      partners(){
            this.$router.push('/partners')
        },
    },
    watch:{
      $route (to, from){
        if(from.name == 'login' && to.name != 'login' && to.name != 'forgotpassword' && to.name != 'register' && to.name != 'partners'){
          this.updateNavBar()
          this.$router.go()   
        }
      }
    },
    created: function () {
      this.updateNavBar()
    },
    mounted: function () {
      this.updateNavBar()
    },
}
</script>

<style>

.image{
    position:relative;
    overflow:hidden;
    padding-bottom:100%;
}
.image img{
    position:absolute;
    margin: auto;
        top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}


/* Modal Components CSS */

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
  overflow-y: auto;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-body{
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

.modal-header{
  border: none;
}

.modal-footer{
  padding: 1.5rem;
  border: none;
}

</style>