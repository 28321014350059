<template>
    <!-- INICIO MAIN ------------------------------------- -->
    <section class="">
        <!-- <?php include('parts/navs/nav-sidebar.php'); ?> -->
        <!-- INICIO CONTENT -->
        <div class="col p-0" ref="topPage">
            <div class="d-md-flex justify-content-between align-items-center mb-3">
                <h2 class="mb-0">Atualizar Relógio</h2>
            </div>
            <div class="container p-0">
                <div v-if="hasErrors" class="alert alert-danger" role="alert">
                    <div v-for="(v, k) in errors" :key="k">
                        <small>{{v.join(' ')}}</small>
                    </div>
                </div>

                <form @submit.prevent="update()">
                    <div v-if="!canEditBrandModelSerial" class="alert alert-warning">
                        Não é possível editar a marca, modelo e serial deste relógio pois ele já foi transferido através do relógio seguro.
                    </div>

                    <div class="form-floating mb-2">
                        <select v-model="form.watch.id_brand" :disabled='!canEditBrandModelSerial' class="form-control form-control-sm" required>
                            <option value="">Selecione um Marca</option>
                            <option v-for="brand in brands" :key="brand.id" v-bind:value="brand.id">
                                {{brand.name}}
                            </option>
                        </select>
                        <label for="floatingText">Marca</label>
                    </div>
                    
                    <div class="form-floating mb-2">
                        <select v-model="form.watch.model.name" class="form-control form-control-sm" required>
                            <option value="">Selecione um Modelo</option>
                            <option v-for="model in modelNames" :key="model.name" v-bind:value="model.name">
                                {{model.name}}
                            </option>
                        </select>
                        <label for="floatingText">Modelo</label>
                    </div>

                    <div class="form-floating mb-2">
                        <select v-model="form.watch.id_model" :disabled='!canEditBrandModelSerial' class="form-control form-control-sm" required>
                            <option value="">Selecione um Código de Referência</option>
                            <option v-for="model in models" :key="model.id" v-bind:value="model.id">
                                {{model.code}} - {{model.description}}
                            </option>
                        </select>
                        <label for="floatingText">Codigo</label>
                    </div>  

                    <div class="form-floating mb-2">
                        <input v-model="form.watch.serial" :disabled='!canEditBrandModelSerial' type="text" maxlength="128" class="form-control form-control-sm" required autofocus>
                        <label for="floatingText">Serial</label>
                    </div>

                    <label>Foto do relógio</label>
                    <div class="cropper-wrapper">
                        <cropper
                            @change="change"
                            minWidth = 500
                            minHeight = 500
                            :src="image"
                            :stencil-props="{
                                handlers: {},
                                //movable: false,
                                scalable: false,
                                resizable: false,
                            }"
                            :stencil-size="{
                                width: 280,
                                height: 280
                            }"
                            image-restriction="stencil"      
                        />
                    </div>
                    <div class="button-wrapper">
                        <span class="button mb-2" @click="$refs.file.click()">
                            <input type="file" ref="file" @change="loadImage($event)" accept="image/*">
                            Trocar foto
                        </span>
                    </div>

                    <div class="form-group">
                        <!-- <button type="submit" class="btn btn-primary btn-sm">Salvar</button> -->
                        <button type="submit" class="btn btn-dark" data-bs-toggle="modal" data-bs-target="#modalNovo" >Salvar</button>
                    </div>
                </form>
            </div>
        </div>
        <!-- FIM CONTENT -->
        <Modal ref="modal"/>
    </section>
</template>
<script>

import WatchRelogioSeguroAPI from '@/services/relogioseguroapi/watch'
import Modal from '@/components/Modal'
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
    directives: {
        Cropper
    },
    components: {
        Modal
    },
    data(){
        return {
            form:{
                watch:{
                    id: '',
                    id_brand: '',
                    id_model: '',
                    model: {
                        name: '',
                    },
                    serial: '',
                    image: null,
                    images: [],
                    watch_transfers: [],
                },
            },
            brands: [],
            modelNames: [],
            models: [],
            image: require('@/assets/images/watch_default.png'),
            imageDefault: require('@/assets/images/watch_default.png'),
            errors: null,
            watch: null
        }
    },
    computed: {
        hasErrors() {
            if(this.errors){
                return true
            }
            return false
        },
        canEditBrandModelSerial() {
            if(this.form.watch.watch_transfers.length > 0){
                return false
            }
            return true
        }
    },
    methods: {
        goToTopPage(){
            let element = this.$refs['topPage'];
            let top = element.offsetTop;
            window.scrollTo(0, top);
        },
        update(){
            this.$refs.modal.isLoading = true
            WatchRelogioSeguroAPI.update(this.form).then(response => {
                response;
                this.errors = null
                this.goToTopPage()
                this.$refs.modal.isLoading = false
                this.$refs.modal.isSuccessful = true
            }).catch(e => {
                this.isSuccessful = false
                console.log(e)
                if(e.response){
                    if (Array.isArray(e.response.data.error)){
                        this.errors = e.response.data.error
                    }else{
                        this.errors = e.response.data.error
                    }
                }else{
                    this.errors = [['Ocorreu um erro desconhecido.']]
                }
                this.goToTopPage()
                this.$refs.modal.isLoading = false
            })
        },
        change({canvas}) {
            if (this.image != this.imageDefault){
                if (canvas.width > 500){
                    let img = new Image()
                    img.src = canvas.toDataURL('image/png')
                    let canvas2 = document.createElement('canvas')
                    let ctx = canvas2.getContext('2d')
                    canvas2.width = 500;
                    canvas2.height = 500;
                    ctx.drawImage(canvas, 0, 0, 500, 500)
                    this.form.watch.image = canvas2.toDataURL('image/png')
                }else{
                    this.form.watch.image = canvas.toDataURL('image/png')
                }
            }else{
                this.form.watch.image = null;
            }
        },
        reset() {
			this.image = null;
		},
		loadImage(event) {
			var input = event.target;
			if (input.files && input.files[0]) {
				var reader = new FileReader();
				reader.onload = (e) => {
					this.image = e.target.result;
				};
				reader.readAsDataURL(input.files[0]);
			}
		},
    },
    watch: {
        'form.watch.id_brand': function (newValue, oldValue) {
            if (newValue){
                if (oldValue){
                    this.$refs.modal.isLoading = true
                    WatchRelogioSeguroAPI.modelNames(newValue).then(response => {
                        this.modelNames = response.data;
                        
                        this.form.watch.id_model = '';
                        this.form.watch.model_name = '';
                        this.form.watch.serial = '';
                        this.form.watch.image = null;

                        this.$refs.modal.isLoading = false
                    })
                }
            } else {
                this.form.watch.id_model = '';
                this.form.watch.model.name = '';
                this.form.watch.serial = '';
                this.form.watch.image = null;
            }
        },
        'form.watch.model.name': function (newValue, oldValue) {
            if (newValue){
                if (oldValue){
                    this.$refs.modal.isLoading = true
                    WatchRelogioSeguroAPI.codes(this.form.watch.id_brand,newValue).then(response => {
                        this.models = response.data;
                        this.$refs.modal.isLoading = false
                    })
                }
            }
        },
        'form.watch.id_model': function (newValue, oldValue) {

            if (newValue){
                 this.$refs.modal.isLoading = true
                if(oldValue == ''){
                    if(this.form.watch.images.length > 0){
                        this.image = this.form.watch.images[0].path    
                    }else{
                        let img_path = this.models.filter(function(value){ 
                            return value.id == newValue
                        })[0].image_path
                        this.image = img_path
                        this.imageDefault = img_path
                        this.form.watch.image = null
                    }
                }else{
                        let img_path = this.models.filter(function(value){ 
                            return value.id == newValue
                        })[0].image_path
                        this.image = img_path
                        this.imageDefault = img_path
                        this.form.watch.image = null
                }
                this.$refs.modal.isLoading = false 
            }              
        },
    },
    mounted: function () {
        this.form.watch.id = this.$route.query.idwatch ? this.$route.query.idwatch.trim() : null
        if(!this.form.watch.id){
            this.$router.push('/home')
        }else{
            this.$refs.modal.isLoading = true
            WatchRelogioSeguroAPI.show(this.form.watch.id).then(response => {

                this.watch = response.data

                WatchRelogioSeguroAPI.brands().then(response => {
                    this.brands = response.data
                    WatchRelogioSeguroAPI.modelNames(this.watch.id_brand).then(response => {
                        this.modelNames = response.data
                        WatchRelogioSeguroAPI.codes(this.watch.id_brand, this.watch.model.name).then(response => {
                            this.models = response.data 
                            this.form.watch = this.watch
                            this.$refs.modal.isLoading = false            
                        })
                    })
                })
            }).catch(e => {
                console.log(e)
                this.errors = [['Ocorreu um erro desconhecido.']]
                this.goToTopPage()
                this.$refs.modal.isLoading = false
            })
        }
    },
}
</script>

<style scoped>

.upload-example-cropper {
	border: solid 1px #EEE;
	height: 300px;
	width: 100%;
}

.button-wrapper {
	display: flex;
	/* justify-content: center; */
	margin-top: 17px;
}

.button {
	font-size: 16px;
	padding: 10px 20px;
	background-color: #dce6ed;
	cursor: pointer;
	transition: background 0.5s;
    color: #000;
    border-color: #dce6ed;
}

.button:hover {
	color: #000;
    background-color: #e1eaf0;
    border-color: #e0e9ef;
}

.button input {
	display: none;
}

.cropper-wrapper {
	overflow: hidden;
	position: relative;
	height: 400px;
	background: black;
}

</style>