<template>

<!-- INICIO MAIN ------------------------------------- -->
    <div ref="topPage">
        <div class="row justify-content-center rs-seguranca-mob gap-2">
            <!-- INICIO CONTENT -->
            <div class="col rs-login p-0 p-md-4 px-lg-5 rounded text-center">
            <h2 class="display-6 mb-3">Redefinir Senha</h2>
            <form class="px-md-4" @submit.prevent="store()">
                <div v-if="hasErrors" class="alert alert-danger" role="alert">
                    <div v-for="(v, k) in errors" :key="k">
                        <small>{{v.join(' ')}}</small>
                    </div>
                </div>
                <!-- inputs -->  
                <div class="row row-cols-1 row-cols-md-2 mb-2 g-2">
                    <div class="form-floating col">
                        <input v-model="form.password" type="password" maxlength="9" class="form-control border-0" id="floatingPassword" placeholder="Senha" required autofocus>
                        <label for="floatingPassword">Senha</label>
                    </div>
                    <div class="form-floating col">
                        <input v-model="form.password_confirmation" type="password" maxlength="9" class="form-control border-0" id="floatingPassword" placeholder="Confirmar senha" required autofocus>
                        <label for="floatingPassword">Confirme a senha</label>
                    </div>
                </div>
                <!-- confirma/submit -->  
                <div class="row justify-content-center gap-3">
                    <div class="">
                        <button v-on:click.prevent="resetPassword()" :disabled='isDisabledResetPassword' class="btn btn-primary btn-lg">Redefinir senha</button>
                    </div>
                </div>
            </form>
          </div>
        </div>
        <!-- FIM CONTENT -->  
        <Modal ref="modal"/>
    </div>
    <!-- FIM MAIN  -->
    

    <!-- <div class="container"> -->
        <!-- <div class="row justify-content-center mt-3 mb-3">
            <div class="col-md-6">
                <img src="@/assets/images/rs_logo_full.png" class="img-fluid" width="" />
            </div>
        </div> -->
        <!-- <div class="row justify-content-center">
            <div class="col-md-6">
                <h1>Redefinir senha</h1>
                <div class="card">
                    <div class="card-body">
                        <div v-if="hasErrors" class="alert alert-danger" role="alert">
                            <div v-for="(v, k) in errors" :key="k">
                                <small>{{v.join(' ')}}</small>
                            </div>
                        </div>
                        
                        <label class="col-md-12 col-form-label">Senha</label>
                        <div class="col-md-12">
                            <input v-model="form.password" type="password" class="form-control form-control-sm" name="email" required autofocus>
                        </div>

                        <label class="col-md-12 col-form-label">Confirme a senha</label>
                        <div class="col-md-12">
                            <input v-model="form.password_confirmation" type="password" class="form-control form-control-sm" name="email" required autofocus>
                        </div>

                        <div class="col-md-12 mt-2">
                            <button v-on:click.prevent="resetPassword()" :disabled='isDisabledResetPassword' class="btn btn-primary btn-sm">Redefinir senha</button>
                        </div>
                    </div>
                </div>
            </div> -->
        <!-- </div> -->

        <!-- <Modal ref="modal" /> -->

    <!-- </div> -->
</template>

<script>

import Modal from '@/components/Modal'
import UserRelogioSeguroAPI from '@/services/relogioseguroapi/user'

export default {
    data(){
        return {
            form: { 
                email: '',
                token: '',
                password: '',
                password_confirmation: ''
            },
            errors: null
        }
    },
    components: {
        Modal
    },
    methods: {
        resetPassword(){
            this.$refs.modal.isLoading = true
            UserRelogioSeguroAPI.resetPassword(this.form).then(response => {
                console.log(response)
                this.errors = null
                this.$refs.modal.isLoading = false
                this.$refs.modal.routeRedirect = 'login'
                this.$refs.modal.isSuccessful = true
            }).catch(e => {
                console.log(e)
                if(e.response){
                    if (Array.isArray(e.response.data.error)){
                        this.errors = e.response.data.error
                    }else{
                        this.errors = [[e.response.data.error]]
                    }
                }else{
                    this.errors = [['Ocorreu um erro desconhecido.']]
                }
                this.$refs.modal.isLoading = false
            })     
        }
    },
    computed: {
        isDisabledResetPassword(){
            if (this.form.password.trim() && this.form.password_confirmation.trim() && this.hasTokenAndEmail){
                 return false;
            }
            return true;
        },
        hasTokenAndEmail() {
            if (this.form.token && this.form.email){
                return true
            }
            return false
        },
        hasErrors() {
            if(this.errors){
                return true
            }
            return false
        }
    },
    mounted: function () {
        if(this.$route.query.token){
            this.form.token = this.$route.query.token.trim()
        }
        if(this.$route.query.token){
            this.form.email = this.$route.query.email.trim()
        }
        if(!this.hasTokenAndEmail) {
            this.errors = [['Token e e-mail não encontrados, copie e cole no navegador o link completo enviado por e-mail.']]
        }
    },
}
</script>