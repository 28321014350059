<template>
    
    <!-- INICIO MAIN ------------------------------------- -->
        <div>
        <div class="row justify-content-center rs-seguranca-mob gap-2">
            <!-- INICIO CONTENT -->
            <div class="col rs-login p-0 p-md-4 px-lg-5 rounded text-center">
            <h2 class="display-6 mb-3">Crie a sua conta grátis</h2>
            <div v-if="hasErrors" class="alert alert-danger" role="alert" ref="topPage">
                <div v-for="(v, k) in errors" :key="k">
                    <small>{{v.join(' ')}}</small>
                </div>
            </div>
            <form class="px-md-4" @submit.prevent="store()">
                <!-- inputs -->  
                <div class="row row-cols-1 row-cols-md-2 mb-2 g-2">
                    <div class="form-floating col">
                        <input type="text" v-model="form.user.name" maxlength="128" class="form-control border-0" id="floatingText" placeholder="Apelido" required autofocus>
                        <label for="floatingText">Apelido</label>
                    </div>
                    <div class="form-floating col">
                        <input type="email" v-model="form.user.email" maxlength="128" class="form-control border-0" id="floatingEmail" placeholder="usuario@dominio.com.br" required autofocus>
                        <label for="floatingEmail">Email</label>
                    </div>
                    <div class="form-floating col">
                        <input v-model="form.user.password" type="password" maxlength="9" class="form-control border-0" id="floatingPassword" placeholder="Senha" required autofocus>
                        <label for="floatingPassword">Senha (9 Caracteres)</label>
                    </div>
                    <div class="form-floating col">
                        <input v-model="form.user.password_confirmation" type="password" maxlength="9" class="form-control border-0" id="floatingPassword" placeholder="Confirmar senha" required autofocus>
                        <label for="floatingPassword">Confirme a senha (9 Caracteres)</label>
                    </div>
                </div>
                <!-- confirma/submit -->  
                <div class="row justify-content-center gap-3">
                    <div>
                        <button type="submit" class="btn btn-primary btn-lg">Criar minhar conta</button>
                    </div>
                </div>
            </form>
          </div>
        </div>
        <!-- FIM CONTENT -->  
        <Modal ref="modal"/>
        </div>
    <!-- FIM MAIN  -->
    
</template>

<script>

import UserRelogioSeguroAPI from '@/services/relogioseguroapi/user'
import Modal from '@/components/Modal'

export default {
    components: {
        Modal
    },
    data(){
        return {
            form:{
                user:{
                    name: '',
                    email: '',
                    password: '',
                    password_confirmation: ''
                }
            },
            errors: null
        }
        
    },
    methods: {
        goToTopPage(){
            let element = this.$refs['topPage']
            let top = element.offsetTop
            window.scrollTo(0, top)
        },
        store(){
            
            this.$refs.modal.isLoading = true
            
            UserRelogioSeguroAPI.store(this.form)
                .then(response => {
                    
                    this.form.user = response.data
                    
                    this.errors = null
                    
                    this.$refs.modal.isLoading = false
                    this.$refs.modal.routeRedirect = 'login'
                    this.$refs.modal.isSuccessful = true
                })
                .catch(e => {
                    
                    this.isSuccessful = false;
                    
                    if(e.response){
                        this.errors = e.response.data.error;
                    }else{
                        this.errors = [['Ocorreu um erro desconhecido.']];
                    }

                    this.$refs.modal.isLoading = false;
                })
        }
    },
    computed: {
        hasErrors() {
            if(this.errors){
                return true
            }
            return false
        }
     },
    watch: { },
    mounted: function () { }
}
</script>