<template>
  <!-- INICIO MAIN ------------------------------------- -->
  <section class="">
    <!-- INICIO CONTENT -->
    <div class="col p-0">
      <div class="d-md-flex justify-content-between align-items-center mb-3">
        <h2 class="display-6 mb-0">Transferências</h2>
      </div>
      <div class="container p-0">
        <div class="row">
          <div class="col">
            <!-- PENDENCIAS -->
            <div class="mb-4">
              <!-- aviso -->
              <!-- <?php include('parts/aviso.php'); ?> -->
              <div v-if="hasErrors" class="alert alert-danger" role="alert">
                <div v-for="(v, k) in errors" :key="k">
                  <small>{{ v.join(" ") }}</small>
                </div>
              </div>

                <!-- carrossel de pendências -->
                <!-- CARD -->
                <div v-if="receivedWatchTransfers.length > 0">
                <h4 class="fw-light">Recebidas</h4>
                </div>
                <div v-for="watchTransfer in receivedWatchTransfers" :key="watchTransfer.id" v-bind:value="watchTransfer.id" >
                    <div class="card rs-card-relogio rs-card-relogio-md mb-2">
                        <div class="row g-0">
                            <!-- foto -->
                            <div class="col-md-6">
                                <div class="position-absolute top-0 start-0 p-2">
                                <i class="bi bi-patch-check-fill rs-icone"></i>
                                </div>
                                <div class="bg-light rounded-start h-100 rs-img" v-bind:style="{backgroundImage:'url(' +(watchTransfer.watch.images.length > 0 ? watchTransfer.watch.images[0].path : watchTransfer.watch.model.image_path   ? watchTransfer.watch.model.image_path : require('@/assets/images/watch_default.png')) +')',}">
                                    <a>
                                        <div class="bg-light rounded-start h-100 rs-img" v-bind:style="{backgroundImage:'url(' + (watchTransfer.watch.images.length > 0 ? watchTransfer.watch.images[0].path
                                                : watchTransfer.watch.model.image_path ? watchTransfer.watch.model.image_path : require('@/assets/images/watch_default.png')) +')',}"></div>
                                    </a>
                                </div>
                            </div>

                            <div class="col-md rs-p-alt">
                                <div class="container-fluid h-100 d-flex flex-column">
                                    <div class="row flex-fill">
                                        <!-- infos -->
                                        <div class="col position-relative">
                                            <a><h6 class="card-title m-0">{{ watchTransfer.watch.brand.name }} - {{ watchTransfer.watch.model.name }}</h6></a>
                                            <p class="card-text mb-3 m-lg-0"><small class="text-muted">Data: {{ watchTransfer.created_at | moment }}</small></p>
                                            <p class="card-text mb-3 m-lg-0"><small class="text-muted">Status: {{watchTransfer.watch_transfer_status.name}}</small></p>
                                            <p class="card-text mb-3 m-lg-0"><small class="text-muted">Remetente: {{ watchTransfer.user_from.email }}</small></p>
                                            <p class="card-text mb-3 m-lg-0"><small class="text-muted">Descrição: {{ watchTransfer.watch.model.description }}</small></p>
                                            <p class="card-text mb-3 m-lg-0"><small class="text-muted">Código: {{watchTransfer.watch.model.code}}</small></p>
                                            <p class="card-text mb-3 m-lg-0"><small class="text-muted">Serial: {{ watchTransfer.watch.serial }}</small></p>
                                        </div>
                                        <!-- marca -->
                                         <div class="col-3 position-relative p-0">
                                                 <!-- <div class="position-absolute bottom-0">
                                                     <img class="img-thumbnail border-0" src="https://i.pinimg.com/originals/e7/f8/64/e7f8642fc5dd54f7d8495cacd8a41883.jpg" alt="...">
                                                 </div> -->
                                             </div> 
                                    </div>
                                      
                                          <div class="row rs-p-alt px-0 pb-0">
                                              <div class="row p-0 m-0 g-2 ">
                                                <div class="col d-flex">
                                                  <button v-on:click="accept(watchTransfer.id)" type="button" class="btn btn-primary btn-sm flex-fill">Aceitar</button>
                                                </div>
                                                <div class="col d-flex">
                                                  <button v-on:click="reject(watchTransfer.id)" type="button" class="btn btn-dark btn-sm flex-fill me-2">Recusar</button>
                                                </div>
                                                   <!-- <a class="btn btn-dark btn-sm flex-fill me-2" href="#" role="button">recusar</a>
                                                                      <a class="btn btn-primary btn-sm flex-fill" href="#" role="button" data-bs-toggle="modal" data-bs-target="#modalTransferenciaConfirma" data-bs-dismiss="modal">aceitar</a>  -->
                                              </div>
                                          </div> 
                                       
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- CARD -->
                <h4 class="fw-light">Enviadas</h4>
                <div v-for="watchTransfer in sentWatchTransfers" :key="watchTransfer.id" v-bind:value="watchTransfer.id" >
                    <div class="card rs-card-relogio rs-card-relogio-md mb-2">
                        <div class="row g-0">
                            <!-- foto -->
                            <div class="col-md-6">
                                <div class="position-absolute top-0 start-0 p-2">
                                <i class="bi bi-patch-check-fill rs-icone"></i>
                                </div>
                                <div class="bg-light rounded-start h-100 rs-img" v-bind:style="{backgroundImage:'url(' +(watchTransfer.watch.images.length > 0 ? watchTransfer.watch.images[0].path : watchTransfer.watch.model.image_path   ? watchTransfer.watch.model.image_path : require('@/assets/images/watch_default.png')) +')',}">
                                    <a>
                                        <div class="bg-light rounded-start h-100 rs-img" v-bind:style="{backgroundImage:'url(' + (watchTransfer.watch.images.length > 0 ? watchTransfer.watch.images[0].path
                                                : watchTransfer.watch.model.image_path ? watchTransfer.watch.model.image_path : require('@/assets/images/watch_default.png')) +')',}"></div>
                                    </a>
                                </div>
                            </div>
                            <div class="col-md rs-p-alt">
                                <div class="container-fluid h-100 d-flex flex-column">
                                    <div class="row flex-fill">
                                        <!-- infos -->
                                        <div class="col position-relative">
                                            <a><h6 class="card-title m-0">{{ watchTransfer.watch.brand.name }} - {{ watchTransfer.watch.model.name }}</h6></a>
                                            <p class="card-text mb-3 m-lg-0"><small class="text-muted">Data: {{ watchTransfer.created_at | moment }}</small></p>
                                            <p class="card-text mb-3 m-lg-0"><small class="text-muted">Status: {{watchTransfer.watch_transfer_status.name}}</small></p>
                                            <p class="card-text mb-3 m-lg-0"><small class="text-muted">Remetente: {{ watchTransfer.user_from.email }}</small></p>
                                            <p class="card-text mb-3 m-lg-0"><small class="text-muted">Descrição: {{ watchTransfer.watch.model.description }}</small></p>
                                            <p class="card-text mb-3 m-lg-0"><small class="text-muted">Código: {{watchTransfer.watch.model.code}}</small></p>
                                            <p class="card-text mb-3 m-lg-0"><small class="text-muted">Serial: {{ watchTransfer.watch.serial }}</small></p>
                                        </div>
                                        <!-- marca -->
                                        <!-- <div class="col-3 position-relative p-0">
                                                 <div class="position-absolute bottom-0">
                                                     <img class="img-thumbnail border-0" src="https://i.pinimg.com/originals/e7/f8/64/e7f8642fc5dd54f7d8495cacd8a41883.jpg" alt="...">
                                                 </div>
                                             </div> -->
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>  
              </div>
            </div>
          </div>
        </div>
      <!-- FIM CONTENT -->
      <Modal ref="modal" />
    </div>
  </section>
  <!-- FIM MAIN  -->

  <!-- <div class="container">
    <h1>Transferências de relógios</h1>
        <div class="card">
            <div class="card-body" ref="topPage">
                <div v-if="hasErrors" class="alert alert-danger" role="alert">
                    <div v-for="(v, k) in errors" :key="k">
                        <small>{{v.join(' ')}}</small>
                    </div>
                </div>

                <h1>Recebidas</h1>
                <div class="row">
                    <div v-for="watchTransfer in receivedWatchTransfers" :key="watchTransfer.id" v-bind:value="watchTransfer.id" class="mb-3 col-sm-4">
                        <div class="image">
                            <img :src=" watchTransfer.watch.images.length > 0 ? watchTransfer.watch.images[0].path : (watchTransfer.watch.model.image_path ? watchTransfer.watch.model.image_path : require('@/assets/images/watch_default.png')) " 
                                class="img mx-auto d-block" style="max-height:100%"
                            />
                        </div>
                        <div class="small">
                            Data: {{watchTransfer.created_at | moment}}<br/>
                            Status: {{watchTransfer.watch_transfer_status.name}}<br/>
                            Remetente: {{watchTransfer.user_from.email}}<br/>
                            Marca: {{watchTransfer.watch.brand.name}}<br/>
                            Modelo: {{watchTransfer.watch.model.name}}<br/>
                            Código: {{watchTransfer.watch.model.code}}<br/>
                            Descrição: {{watchTransfer.watch.model.description}}<br/>
                            Serial: {{watchTransfer.watch.serial}}<br/>
                            <div class="row justify-content-center">
                                <button v-on:click="accept(watchTransfer.id)" type="button" class="btn btn-sm btn-success mr-2"> Aceitar </button>
                                <button v-on:click="reject(watchTransfer.id)" type="button" class="btn btn-sm btn-danger"> Recusar </button>
                            </div>
                        </div>
                    </div>
                </div>

                <hr/>

                <h1>Enviadas</h1>

                <div class="row">
                    <div v-for="watchTransfer in sentWatchTransfers" :key="watchTransfer.id" v-bind:value="watchTransfer.id" class="mb-3 col-sm-4">
                        <div class="image">
                            <img :src=" watchTransfer.watch.images.length > 0 ? watchTransfer.watch.images[0].path : (watchTransfer.watch.model.image_path ? watchTransfer.watch.model.image_path : require('@/assets/images/watch_default.png')) " 
                                class="img mx-auto d-block" style="max-height:100%"
                            />
                        </div>
                        <div class="small">
                            Data: {{watchTransfer.created_at | moment}}<br/>
                            Status: {{watchTransfer.watch_transfer_status.name}}<br/>
                            Destinatário: {{watchTransfer.user_to ? watchTransfer.user_to.email : watchTransfer.email_user_to}}<br/>
                            Marca: {{watchTransfer.watch.brand.name}}<br/>
                            Modelo: {{watchTransfer.watch.model.name}}<br/>
                            Código: {{watchTransfer.watch.model.code}}<br/>
                            Descrição: {{watchTransfer.watch.model.description}}<br/>
                            Serial: {{watchTransfer.watch.serial}}<br/>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <Modal ref="modal"/>
    </div> -->
</template>

<script>
import WatchRelogioSeguroAPI from "@/services/relogioseguroapi/watch";
import Modal from "@/components/Modal";
import moment from "moment";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      idUserLogged: null,
      watchTransfers: [],
      errors: null,
    };
  },
  computed: {
    hasErrors() {
      if (this.errors) {
        return true;
      }
      return false;
    },
    receivedWatchTransfers() {
      let idUserLogged = this.idUserLogged;
      return this.watchTransfers.filter(function (value) {
        return value.id_user_to == idUserLogged;
      });
    },
    sentWatchTransfers() {
      let idUserLogged = this.idUserLogged;
      return this.watchTransfers.filter(function (value) {
        return value.id_user_to != idUserLogged;
      });
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YYYY - H:mm");
    },
  },
  methods: {
    goToTopPage() {
      let element = this.$refs["topPage"];
      let top = element.offsetTop;
      window.scrollTo(0, top);
    },
    updateWatchTransfer(form) {
      this.$refs.modal.isLoading = true;
      WatchRelogioSeguroAPI.transferUpdate(form)
        .then((response) => {
          console.log(response.data);
          this.watchTransfers = this.watchTransfers.filter(function (value) {
            return value.id != form.watchTransfer.id;
          });
          this.$refs.modal.isLoading = false;
          this.$refs.modal.isSuccessful = true;
        })
        .catch((e) => {
          console.log(e);
          if (e.response) {
            if (Array.isArray(e.response.data.error)) {
              this.errors = e.response.data.error;
            } else {
              this.errors = [[e.response.data.error]];
            }
          } else {
            this.errors = [["Ocorreu um erro desconhecido."]];
          }
          this.goToTopPage();
          this.$refs.modal.isLoading = false;
        });
    },
    accept(idWatchTransfer) {
      this.$confirm({
        message: "Tem certeza que gostaria de aceitar a transferência?",
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            let form = {
              watchTransfer: {
                id: idWatchTransfer,
                accept: true,
              },
            };
            this.updateWatchTransfer(form);
          }
        },
      });
    },
    reject(idWatchTransfer) {
      this.$confirm({
        message: "Tem certeza que gostaria de recusar a transferência?",
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            let form = {
              watchTransfer: {
                id: idWatchTransfer,
                accept: false,
              },
            };
            this.updateWatchTransfer(form);
          }
        },
      });
    },
  },
  mounted: function () {
    this.$refs.modal.isLoading = true;
    this.idUserLogged = JSON.parse(localStorage.getItem("userLogged")).id;
    WatchRelogioSeguroAPI.transferList()
      .then((response) => {
        this.watchTransfers = response.data;
        this.$refs.modal.isLoading = false;
      })
      .catch((e) => {
        console.log(e);
        if (e.response) {
          if (Array.isArray(e.response.data.error)) {
            this.errors = e.response.data.error;
          } else {
            this.errors = [[e.response.data.error]];
          }
        } else {
          this.errors = [["Ocorreu um erro desconhecido."]];
        }
        this.goToTopPage();
        this.$refs.modal.isLoading = false;
      });
  },
};
</script>