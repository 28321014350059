<template>
    <div>
        <!-- Modal Aguarde -->
        <div v-if="showLoading" >
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-sm">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner-border" role="status">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <p>{{textLoading}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <!-- Modal Sucesso -->
        <div v-if="showSuccessful" >
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-sm">
                            <div class="modal-content">
                                <div class="modal-body" style="padding: 0.5rem;">
                                    <div class="d-flex justify-content-center mb-3 mt-2">
                                        <i class="far fa-check-circle text-success fa-3x"></i>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        {{textSuccessful}}
                                    </div>
                                </div>
                                <div class="row p-0 px-md-4 m-0 g-2 text-center">
                                    <div class="col d-flex">
                                        <button v-on:click="hideSuccessful()" type="button" class="btn btn-dark btn-sm p-2 flex-fill">OK</button>
                                    </div>
                                </div>
                                <div class="modal-footer" style="padding: 0.5rem;">
                                    <!-- <button v-on:click="hideSuccessful()" type="button" class="btn btn-dark">OK</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div> 
        <!-- <div v-if="showSuccessful" >
            <transition name="modal">    
                <div class="modal" id="modalNovo" tabindex="-1" aria-labelledby="modalNovoLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content border-0">
                            <div class="modal-header border-0">
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body pt-0">                    
                                <div class="text-center">
                                    <div class="text-center mb-3"><img src="assets/img/ilustras/rs-ilustra-add-lg.svg" class="rs-ilustra-lg rs-ilustra-cor-1" alt=""></div>
                                    <h6 class="modal-title text-center display-6 mb-2 mb-sm-3" id="modalNovoLabel">Seja bem vindo!</h6>                    
                                </div>
                                <div>
                                    <p class="modal-title text-center mb-3" id="modalTransferenciaConfereLabel">{{textSuccessful}}</p>
                                    <div class="text-center d-flex justify-content-center">
                                        <button v-on:click="hideSuccessful()" type="button" class="rs-btn-add rounded-circle"></button>
                                        <a type="" class="rs-btn-add rounded-circle" href="app-relogio-novo.php">
                                            <i class="bi bi-plus d-flex"></i>
                                        </a>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>  -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            isLoading : false,
            isSuccessful: false,
            textSuccessful: 'Operação realizada com sucesso',
            textLoading: 'Aguarde ...',
            routeRedirect: null,
        }
    },
    computed: {
        showLoading() {
            return this.isLoading
        },
        showSuccessful() {
            return this.isSuccessful
        }
    },
    methods : {
        hideSuccessful(){
            this.isSuccessful = false
            if (this.routeRedirect) {
                this.$router.push(this.routeRedirect)
            }
        }
    },
    watch: {
        'visible': function (newValue) {
            if (newValue){
                document.getElementsByTagName('body')[0].classList.add('modal-open');
            }else{
                document.getElementsByTagName('body')[0].classList.remove('modal-open');
            }
        },
    }
}
</script>