<template>
    <div class="container p-0">
        <div class="row">
            <div class="col" ref="topPage">
                
                        <div v-if="hasErrors" class="alert alert-danger" role="alert">
                            <div v-for="(v, k) in errors" :key="k">
                                <small>{{v.join(' ')}}</small>
                            </div>
                        </div>


                        <div v-for="watch in watches" :key="watch.id" v-bind:value="watch.id" class="card rs-card-relogio rs-card-relogio-md mb-2">
                            <div class="row g-0">
                                <!-- menu -->
                                <div class="position-relative rs-nav-opcoes">
                                    <ul class="list-unstyled position-absolute top-0 end-0 border-0 rounded py-2 ps-3 pe-4 m-1 collapse">
                                        <li class="mb-1"><a style="cursor: pointer;" v-on:click="updateWatch(watch.id)"><small>Editar</small></a></li>
                                        <li class="mb-1" v-if="verifyDados()"><a style="cursor: pointer;" v-on:click="reportWatch(watch)"><small>Reportar</small></a></li>
                                        <li class="mb-1" v-if="!verifyDados()"><a style="cursor: pointer;" v-on:click="abrirModalUpdateInfo()"><small>Reportar</small></a></li>
                                        <li class="mb-1"><a style="cursor: pointer;" v-on:click="transferWatch(watch)"><small>Transferir</small></a></li>
                                        <li class="mb-1"><a style="cursor: pointer;" v-on:click="confirmDelete(watch.id, watch.brand.name + ' - ' + watch.model.name + ' (' + watch.model.code +')' + ' ' + watch.serial)"><small>Excluir</small></a></li>
                                    </ul>
                                    <a href="" type="" class="position-absolute top-0 end-0 m-2 pt-1 collapsed" data-bs-toggle="collapse" data-bs-target=".collapse" aria-expanded="false"><i class="bi bi-three-dots-vertical h4 m-0 d-block"></i></a>
                                </div>

                                <!-- foto -->
                                <div class="col-md-6">
                                    <div class="position-absolute top-0 start-0 p-2"><i class="bi bi-patch-check-fill rs-icone"></i></div>
                                    <div class="bg-light rounded-start h-100 rs-img" v-bind:style="{ backgroundImage: 'url(' + (watch.images.length > 0 ? watch.images[0].path : (watch.model.image_path ? watch.model.image_path : require('@/assets/images/watch_default.png'))) + ')' }">
                                        <a>
                                            <div class="bg-light rounded-start h-100 rs-img" v-bind:style="{ backgroundImage: 'url(' + (watch.images.length > 0 ? watch.images[0].path : (watch.model.image_path ? watch.model.image_path : require('@/assets/images/watch_default.png'))) + ')' }"></div>
                                        </a>
                                    </div>
                                </div>

                                <!-- dados -->
                                <div class="col-md rs-p-alt">
                                    <div class="container-fluid h-100 d-flex flex-column">
                                        <div class="row flex-fill">
                                            <div class="col position-relative">
                                                <a><h6 class="card-title m-0">{{watch.brand.name}} - {{watch.model.name}}</h6></a>
                                                <p class="card-text mb-3 m-lg-0"><small class="text-muted">{{watch.model.code}}</small></p>
                                                <p class="card-text mb-3 m-lg-0"><small class="text-dark">{{watch.model.description}}</small></p>
                                                <p class="card-text bottom-0 text-dark position-absolute bottom-0">
                                                    <small>{{watch.serial}}</small>
                                                </p> 
                                            </div>
                                        </div>                                        
                                    </div>

                                </div>



                            </div>
                        </div>
                <Modal ref="modal"/>
                <WatchTransferModal ref="watchTransferModal" :transferWatchSuccessful='transferWatchSuccessful'/>
                <WatchReportModal ref="watchReportModal" :reportWatchSuccessful='reportWatchSuccessful'/>
                <ModalUpdateInfo ref="ModalUpdateInfo"/>
            </div>
        </div>
    </div>
</template>

<script>

import UserRelogioSeguroAPI from '@/services/relogioseguroapi/user'
import WatchRelogioSeguroAPI from '@/services/relogioseguroapi/watch'
import Modal from '@/components/Modal'
import WatchTransferModal from '@/components/WatchTransferModal'
import WatchReportModal from '@/components/WatchReportModal'
import ModalUpdateInfo from '@/components/ModalUpdateInfo'

export default {
    components:{
        Modal,
        WatchTransferModal,
        WatchReportModal,
        ModalUpdateInfo
    },
    data(){
        return {
            watches: [],
            errors: null,
        }
        
    },
    methods: {
        reportWatchSuccessful(idWatch) {
            this.watches = this.watches.filter(function(value){ 
                return value.id != idWatch
            })
        },
        transferWatchSuccessful(idWatch) {
            this.watches = this.watches.filter(function(value){ 
                return value.id != idWatch
            })
        },
        reportWatch(watch){
            this.$refs.watchReportModal.form.occurrence.id_watch = watch.id
            this.$refs.watchReportModal.watch = watch
            this.$refs.watchReportModal.visible = true
        },
        transferWatch(watch){
            this.$refs.watchTransferModal.form.watchTransfer.id_watch = watch.id
            this.$refs.watchTransferModal.watch = watch
            this.$refs.watchTransferModal.visible = true
        },
        updateWatch(idWatch){
            this.$router.push('/updatewatch?idwatch=' + idWatch)
        },
        updateDadosPessoais(){
            this.$router.push('/account');
        },
        confirmDelete(idWatch, watchText){
            this.$confirm(
                {
                message: "Tem certeza que gostaria de apagar o relógio " + watchText + "?",
                button: {
                    no: 'Não',
                    yes: 'Sim'
                },
                callback: confirm => {
                    if (confirm) {
                        this.deleteWatch(idWatch)
                    }
                }
            })
        },
        goToTopPage(){
            let element = this.$refs['topPage']
            let top = element.offsetTop
            window.scrollTo(0, top)
        },
        deleteWatch(idWatch){
            this.$refs.modal.isLoading = true
            WatchRelogioSeguroAPI.delete(idWatch).then(response => {
                response;// Tirei do console.log() para evitar que os dados fossem para a tela
                this.watches = this.watches.filter(function(value){ 
                    return value.id != idWatch
                })
                this.$refs.modal.isLoading = false
                this.$refs.modal.isSuccessful = true
            }).catch(e => {
                if(e.response){
                    if (Array.isArray(e.response.data.error)){
                            this.errors = e.response.data.error
                        }else{
                            this.errors = [[e.response.data.error]]
                        }
                    }else{
                        this.errors = [['Ocorreu um erro desconhecido.']]
                }
                this.goToTopPage()
                this.$refs.modal.isLoading = false
            })
        },
        verifyDados(){

            //Verifica USUARIO
            if(this.user && this.user != null) {
                
                //Verifica CPF
                if(this.user.cpf && this.user.cpf != null && this.user.cpf.trim() != ""){

                    //Verifica ENDERECO
                    if(this.user.address && this.user.address != null){
                        if(this.user.address.id_uf && this.user.address.id_uf != null &&
                            this.user.address.id_city && !this.user.address.id_city != null){
                            return true;    
                        }  
                    }
                }
                
            }

            return false;
        },
        abrirModalUpdateInfo(){
            this.$refs.ModalUpdateInfo.isVisible = true;
        }
    },
    mounted: function () {
        this.$refs.modal.isLoading = true;
        this.user = null;

        UserRelogioSeguroAPI.show().then(response => { 
            
            this.user = response.data;
        });

        WatchRelogioSeguroAPI.list().then(response => {
            this.watches = response.data
            this.$refs.modal.isLoading = false
        }).catch(e => {
            console.log(e)
            if(e.response){
                if (Array.isArray(e.response.data.error)){
                        this.errors = e.response.data.error
                    }else{
                        this.errors = [[e.response.data.error]]
                    }
                }else{
                    this.errors = [['Ocorreu um erro desconhecido.']]
            }
            this.goToTopPage()
            this.$refs.modal.isLoading.visible = false
        })
    },
    computed: {
        hasErrors() {
            if(this.errors){
                return true
            }
            return false
        }
    }
}
</script>

