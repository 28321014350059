import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home'
import Login from '@/views/Login'
import Account from '@/views/Account'
import ForgotPassword from '@/views/ForgotPassword'
import ResetPassword from '@/views/ResetPassword'
import ConfirmMail from '@/views/ConfirmMail'
import Register from '@/views/Register'
import StoreWatch from '@/views/StoreWatch'
import UpdateWatch from '@/views/UpdateWatch'
import WatchTransfer from '@/views/WatchTransfer'
import WatchReport from '@/views/WatchReport'
import Partner from '@/views/Partner'

Vue.use(VueRouter)

const routes = [
    { 
        name: '/',
        path: '/',
        beforeEnter: () => {
            window.location.href = '/assets/pages/index.html';
        }
    },
    {
        name: 'login',
        path: '/login',
        component: Login
    },
    {
        name: 'home',
        path: '/home',
        component: Home
    },
    {
        name: 'account',
        path: '/account',
        component: Account
    },
    {
        name: 'forgotpassword',
        path: '/forgotpassword',
        component: ForgotPassword
    },
    {
        name: 'resetpassword',
        path: '/resetpassword',
        component: ResetPassword
    },   
    {
        name: 'confirmmail',
        path: '/confirmmail',
        component: ConfirmMail
    },
    {
        name: 'register',
        path: '/register',
        component: Register
    },
    {
        name: 'storewatch',
        path: '/storewatch',
        component: StoreWatch
    },
    {
        name: 'updatewatch',
        path: '/updatewatch',
        component: UpdateWatch
    },
    {
        name: 'watchtransfer',
        path: '/watchtransfer',
        component: WatchTransfer
    },
    {
        name: 'watchreport',
        path: '/watchreport',
        component: WatchReport
    },
    {
        name: 'partners',
        path: '/partners',
        component: Partner
    },
]

const router = new VueRouter({ routes })

router.beforeEach((to, from, next) => {
    const publicPages = [
        
        '/',
        '/login',
        '/forgotpassword',
        '/resetpassword',
        '/confirmmail',
        '/register',
        '/partners',
    ];
    const authRequired = !publicPages.includes(to.path);
    const userLogged = localStorage.getItem('userLogged');
  
    if (authRequired && !userLogged) {
      return next('/login');
    }
  
    next();
  })

export default router