<template>
    <div v-if="isVisible" >
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header pb-0">
                                <div v-if="showWizardStepUserMail || showWizardStepConfirm" class="text-center mb-3 w-100 text-center">
                                    <img src="assets/img/ilustras/rs-ilustra-transferencia-lg.svg" class="rs-ilustra-lg rs-ilustra-alerta" alt="">
                                </div>
                            </div>
                            <div class="modal-body py-0">
                                <div v-if="hasErrors" class="alert alert-danger" role="alert">
                                    <div v-for="(v, k) in errors" :key="k">
                                        <small>{{v.join(' ')}}</small>
                                    </div>
                                </div>

                                <div class="justify-content-center">
                                    
                                    <div v-if="showWizardStepLoading" class="mt-4" >    
                                        <div class="d-flex justify-content-center">
                                            <div class="spinner-border" role="status">
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <p>{{textLoading}}</p>
                                        </div>
                                    </div>

                                    <form v-if="showWizardStepUserMail" @submit.prevent="checkEmail()">



                                        <div class="card rs-card-relogio rs-card-relogio-sm mb-2">
                                            <div class="row g-0">
                                                <!-- foto -->
                                                <div class="col-4 col-md-2">
                                                    <div class="position-absolute top-0 start-0 p-1"><i class="bi bi-patch-check-fill rs-icone"></i></div>
                                                    <div class="bg-light rounded-start h-100 rs-img" v-bind:style="{ backgroundImage: 'url(' + (watch.images.length > 0 ? watch.images[0].path : (watch.model.image_path ? watch.model.image_path : require('@/assets/images/watch_default.png'))) + ')' }">
                                                        <div class="bg-light rounded-start h-100 rs-img" v-bind:style="{ backgroundImage: 'url(' + (watch.images.length > 0 ? watch.images[0].path : (watch.model.image_path ? watch.model.image_path : require('@/assets/images/watch_default.png'))) + ')' }"></div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col">
                                                    <div class="card-body container p-2 h-100">
                                                        <div class="row h-100">
                                                            <!-- infos -->
                                                            <div class="col position-relative">
                                                                <h6 class="card-title m-0">{{watch.brand.name}} - {{watch.model.name}}</h6>
                                                                <p class="card-text mb-3 m-lg-0"><small class="text-muted">{{watch.model.code}}</small></p>
                                                                <!-- <p class="card-text mb-3 m-lg-0"><small class="text-dark">{{watch.model.description}}</small></p> -->
                                                                <p class="card-text bottom-0 text-dark position-absolute bottom-0">
                                                                    <small>{{watch.serial}}</small>
                                                                </p> 
                                                            </div>
                                                        </div>                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-floating mb-2">
                                            <input required autofocus class="form-control" type="email" v-model="form.user.email" maxlength="128">
                                            <label>Transferir para o e-mail...</label>
                                        </div>

                                        <div class="row p-0 px-md-4 m-0 g-2 text-center">
                                            <div class="col d-flex">
                                                <button v-on:click="hide()" type="button" class="btn btn-secondary btn-sm p-2 flex-fill">Cancelar</button>
                                            </div>
                                            <div class="col d-flex">
                                                <button type="submit" class="btn btn-dark btn-sm p-2 flex-fill">Continuar</button>
                                            </div>
                                        </div>

                                        <!-- <div class="form-group">
                                            <label>E-Mail do destinatário</label>
                                            <input v-model="form.user.email" type="email" maxlength="128" class="form-control form-control-sm" required autofocus>
                                        </div> -->


                                        <!-- <div class="form-group">
                                            <button v-on:click="hide()" type="button" class="btn btn-secondary btn-sm mr-2">Cancelar</button>
                                            <button type="submit" class="btn btn-primary btn-sm">Seguir</button>
                                        </div> -->
                                    </form>

                                    <form v-if="showWizardStepConfirm" @submit.prevent="transferWatch()">
                                        <div v-if="!showNameInput" class="form-group">
                                            <div class="form-floating mb-2">
                                                <input v-model="form.watchTransfer.email_user_to" disabled class="form-control">
                                                <label>E-Mail do destinatário</label>
                                            </div>
                                        </div>
                                        <div v-if="showNameInput" class="form-group">
                                            <div class="alert alert-warning small text-justify">
                                                <span><b>Atenção:</b> Não foi encontrado um usuário com o e-mail <b>{{this.form.user.email}}</b> mas não se preocupe, assim que alguém se cadastrar com este e-mail o relógio será transferido automáticamente para este usuário.</span>
                                            </div>
                                            <div class="form-floating mb-2">
                                                <input v-model="form.watchTransfer.name_user_to" maxlength="128" class="form-control" required autofocus>
                                                <label>Nome do destinatário</label>
                                            </div>
                                        </div>
                                        
                                        <!-- <div class="image">
                                            <img :src=" watch.images.length > 0 ? watch.images[0].path : (watch.model.image_path ? watch.model.image_path : require('@/assets/images/watch_default.png')) " 
                                                class="img mx-auto d-block" style="max-height:100%"
                                            />
                                        </div> -->


                                        <div class="card rs-card-relogio rs-card-relogio-sm mb-2">
                                            <div class="row g-0">
                                                <!-- foto -->
                                                <div class="col-4 col-md-2">
                                                    <div class="position-absolute top-0 start-0 p-1"><i class="bi bi-patch-check-fill rs-icone"></i></div>
                                                    <div class="bg-light rounded-start h-100 rs-img" v-bind:style="{ backgroundImage: 'url(' + (watch.images.length > 0 ? watch.images[0].path : (watch.model.image_path ? watch.model.image_path : require('@/assets/images/watch_default.png'))) + ')' }">
                                                        <div class="bg-light rounded-start h-100 rs-img" v-bind:style="{ backgroundImage: 'url(' + (watch.images.length > 0 ? watch.images[0].path : (watch.model.image_path ? watch.model.image_path : require('@/assets/images/watch_default.png'))) + ')' }"></div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col">
                                                    <div class="card-body container p-2 h-100">
                                                        <div class="row h-100">
                                                            <!-- infos -->
                                                            <div class="col position-relative">
                                                                <h6 class="card-title m-0">{{watch.brand.name}} - {{watch.model.name}}</h6>
                                                                <p class="card-text mb-3 m-lg-0"><small class="text-muted">{{watch.model.code}}</small></p>
                                                                <!-- <p class="card-text mb-3 m-lg-0"><small class="text-dark">{{watch.model.description}}</small></p> -->
                                                                <p class="card-text bottom-0 text-dark position-absolute bottom-0">
                                                                    <small>{{watch.serial}}</small>
                                                                </p> 
                                                            </div>
                                                        </div>                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="small">
                                            Marca: {{watch.brand.name}}<br/>
                                            Modelo: {{watch.model.name}}<br/>
                                            Código: {{watch.model.code}}<br/>
                                            Descrição: {{watch.model.description}}<br/>
                                            Serial: {{watch.serial}}<br/>
                                        </div> -->

                                        <div class="row p-0 px-md-4 m-0 g-2 text-center">
                                            <div class="col d-flex">
                                                <button v-on:click="hide()" type="button" class="btn btn-secondary btn-sm p-2 flex-fill">Cancelar</button>
                                            </div>
                                            <div class="col d-flex">
                                                <button type="submit" class="btn btn-dark btn-sm p-2 flex-fill">Transferir</button>
                                            </div>
                                        </div>

                                        <!-- <div class="form-group mt-2">
                                            <button v-on:click="hide()" type="button" class="btn btn-secondary btn-sm mr-2">Cancelar</button>
                                            <button type="submit" class="btn btn-success btn-sm">Transferir</button>
                                        </div> -->
                                    </form>

                                    <div v-if="showWizardStepSuccessful" >    
                                        <div class="d-flex justify-content-center mb-3 mt-2">
                                            <i class="far fa-check-circle text-success fa-3x"></i>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            {{textSuccessful}}
                                        </div>
                                        <div class="row p-0 px-md-4 m-0 g-2 text-center">
                                            <div class="col d-flex">
                                                <button v-on:click="hide()" type="button" class="btn btn-dark btn-sm p-2 flex-fill">OK</button>
                                            </div>
                                        </div>     
                                    </div>

                                </div>
                            </div>
                            <div class="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>    
</template>

<script>

import UserRelogioSeguroAPI from '@/services/relogioseguroapi/user'
import WatchRelogioSeguroAPI from '@/services/relogioseguroapi/watch'

export default {
    data() {
        return {
            form:{
                user:{
                    email: null
                },
                watchTransfer:{
                    id_watch: null,
                    email_user_to: null,
                    name_user_to: null,
                    exists_email: false,
                }
            },
            watch: null,
            visible : false,
            errors: null,
            //wizardStep: ['loading','userEmail','confirm'],
            currentwizardStep: 'userEmail',
            textLoading: 'Aguarde ...',
            textSuccessful: 'Operação realizada com sucesso.'
        }
    },
    props: {
        transferWatchSuccessful: { type: Function },
    },
    computed: {
        isVisible() {
            return this.visible
        },
        hasErrors() {
            if(this.errors){
                return true
            }
            return false
        },
        showWizardStepUserMail(){
            if(this.currentwizardStep == 'userEmail'){
                return true
            }
            return false
        },
        showWizardStepConfirm(){
            if(this.currentwizardStep == 'confirm'){
                return true
            }
            return false
        },
        showWizardStepLoading(){
            if(this.currentwizardStep == 'loading'){
                return true
            }
            return false
        },
        showWizardStepSuccessful(){
            if(this.currentwizardStep == 'successful'){
                return true
            }
            return false
        },
        showNameInput(){
            if(this.form.watchTransfer.exists_email){
                return false
            }
            return true
        }
    },
    methods : {
        hide(){
            this.form.watchTransfer.exists_email = false
            this.form.watchTransfer.name_user_to = null
            this.form.watchTransfer.email_user_to = null
            this.form.user.email = null
            this.error = null
            this.currentwizardStep = 'userEmail'
            this.visible = false
        },
        show(){
            this.visible = true
        },
        checkEmail(){
            this.currentwizardStep = 'loading'
            UserRelogioSeguroAPI.exists(this.form).then(response => {
                this.form.watchTransfer.email_user_to = this.form.user.email
                this.errors = null
                this.form.watchTransfer.exists_email = response.data.exists
                if(this.form.watchTransfer.exists_email){
                    this.form.watchTransfer.name_user_to = this.form.watchTransfer.email_user_to
                }
                this.currentwizardStep = 'confirm'
            }).catch(e => {
                console.log(e)
                this.emailText = ''
                if(e.response){
                    this.errors = e.response.data.error
                }else{
                    this.errors = [['Ocorreu um erro desconhecido.']]
                }
                this.currentwizardStep = 'userEmail'
            })
        },
        transferWatch(){
            this.$confirm(
                {
                message: "Atenção! Esta ação é irreversível! Deja executar a transferência do relógio?",
                button: {
                    no: 'Não',
                    yes: 'Sim'
                },
                callback: confirm => {
                    if (confirm) {
                        this.currentwizardStep = 'loading'
                        WatchRelogioSeguroAPI.transfer(this.form).then(response => {
                            console.log(response)
                            this.errors = null
                            this.currentwizardStep = 'successful'
                            this.transferWatchSuccessful(this.watch.id)
                        }).catch(e => {
                            console.log(e)
                            if(e.response){
                                this.errors = e.response.data.error
                            }else{
                                this.errors = [['Ocorreu um erro desconhecido.']]
                            }
                            this.currentwizardStep = 'confirm'
                        })
                    }
                }
            })
        }
    },
    watch: {
        'visible': function (newValue) {
            if (newValue){
                document.getElementsByTagName('body')[0].classList.add('modal-open');
            }else{
                document.getElementsByTagName('body')[0].classList.remove('modal-open');
            }
        },
    }
}
</script>