<template>
    <div>
            <div class="d-md-flex justify-content-between align-items-center mb-3">
              <h2 class="display-6 mb-0">Ocorrências</h2>
            </div>  
            <div class="" ref="topPage">
                <div v-if="hasErrors" class="alert alert-danger" role="alert">
                    <div v-for="(v, k) in errors" :key="k">
                        <small>{{v.join(' ')}}</small>
                    </div>
                </div>
                <div class="row">
                    <div v-for="report in reports" :key="report.id" v-bind:value="report.id" class="card rs-card-relogio rs-card-relogio-sm rs-card-relogio-ocorrencia mb-2">

                        <div class="row g-0">
                            <div class="col-4 col-md-3">
                                <div class="position-absolute top-0 start-0 p-1"><i class="bi bi-patch-check-fill rs-icone"></i></div>
                                <div class="bg-light rounded-start h-100 rs-img" v-bind:style="{ backgroundImage: 'url(' + (report.watch.images.length > 0 ? report.watch.images[0].path : (report.watch.model.image_path ? report.watch.model.image_path : require('@/assets/images/watch_default.png'))) + ')' }">
                                    <div class="bg-light rounded-start h-100 rs-img" v-bind:style="{ backgroundImage: 'url(' + (report.watch.images.length > 0 ? report.watch.images[0].path : (report.watch.model.image_path ? report.watch.model.image_path : require('@/assets/images/watch_default.png'))) + ')' }"></div>
                                </div>
                            </div>
                            
                            <div class="col">            
                                <div class="card-body container p-2 h-100">
                                    <div class="row h-100">
                                        <div class="col position-relative">
                                            <h6 class="card-title mb-3 m-lg-0">{{report.watch.brand.name}} - {{report.watch.model.name}} - {{report.occurrence_type.name}} - {{report.occurrence_date | moment}}</h6>
                                            <p class="card-text mb-3 m-lg-0"><small class="text-muted">{{report.watch.model.code}}</small></p>
                                            <p class="card-text mb-3 m-lg-0"><small class="text-muted">Serial {{report.watch.serial}}</small></p>
                                            <p class="card-text mb-3 m-lg-0"><small class="text-muted">Descrição: {{report.description}}</small></p>
                                            <!-- <p>
                                                <span class="text-dark fw-light text-uppercase">{{report.occurrence_type.name}}</span>
                                            </p>  -->

                                            <span v-if="report.images.length > 0" class="card-text mb-3 m-lg-0">Boletim de ocorrência</span>
                                            <div v-if="report.images.length > 0" class="row">
                                                <div v-for="image in report.images" :key="image.id" v-bind:value="image.id" class="col-auto">
                                                    <a :href="image.path" target="_blank" class="" style="cursor: pointer;">
                                                        <i class="far fa-lg fa-image"></i>
                                                    </a>
                                                    <!-- <a :href="image.path" target="_blank" class="btn btn-sm" style="cursor: pointer;">
                                                        <img :src="image.path" style="width:30px;" />
                                                    </a> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>                    
                                </div>
                            </div>
                        </div>

                        <!-- <div class="image">
                            <img :src=" report.watch.images.length > 0 ? report.watch.images[0].path : (report.watch.model.image_path ? report.watch.model.image_path : require('@/assets/images/watch_default.png')) " 
                                class="img mx-auto d-block" style="max-height:100%"
                            />
                        </div>
                        <div class="small">
                            Marca: {{report.watch.brand.name}}<br/>
                            Modelo: {{report.watch.model.name}}<br/>
                            Código: {{report.watch.model.code}}<br/>
                            Descrição: {{report.watch.model.description}}<br/>
                            Serial: {{report.watch.serial}}<br/>

                            <b>Ocorrência</b><br/>
                            Data: {{report.occurrence_date | moment}}<br/>
                            Tipo: {{report.occurrence_type.name}}<br/>
                            Descrição: {{report.description}}<br/>
                            
                            <b v-if="report.images.length > 0">Boletim de ocorrência</b>
                            <div v-if="report.images.length > 0" class="row">
                                <div v-for="image in report.images" :key="image.id" v-bind:value="image.id" class="col-auto">
                                    <a :href="image.path" target="_blank" class="btn btn-sm" style="cursor: pointer;">
                                        <img :src="image.path" style="width:70px;" />
                                    </a>
                                </div>
                            </div>
                        
                        </div> -->
                    </div>
                </div>
            </div>
        <Modal ref="modal"/>
    </div>
</template>

<script>

import WatchRelogioSeguroAPI from '@/services/relogioseguroapi/watch'
import Modal from '@/components/Modal'
import moment from 'moment'

export default {
    components:{
        Modal
    },
    data() {
        return {
            idUserLogged: null,
            reports: [],
            errors: null,
        }
    },
    computed:{
        hasErrors() {
            if(this.errors){
                return true
            }
            return false
        },
    },
    filters: {
        moment: function (date) {
            return moment(date).format('DD/MM/YYYY');
        }
    },
    methods: {
        goToTopPage(){
            let element = this.$refs['topPage']
            let top = element.offsetTop
            window.scrollTo(0, top)
        },
    },    
    mounted: function () {
        this.$refs.modal.isLoading = true
        this.idUserLogged = JSON.parse(localStorage.getItem('userLogged')).id
        WatchRelogioSeguroAPI.reportList().then(response => {
            this.reports = response.data
            this.$refs.modal.isLoading = false
        }).catch(e => {
            console.log(e)
            if(e.response){
                if (Array.isArray(e.response.data.error)){
                        this.errors = e.response.data.error
                    }else{
                        this.errors = [[e.response.data.error]]
                    }
                }else{
                    this.errors = [['Ocorreu um erro desconhecido.']]
            }
            this.goToTopPage()
            this.$refs.modal.isLoading = false
        })
    },
}
</script>